import React, { Fragment } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';

import BaseStore from '../../services/BaseStore';
import CustomLoadingCard from '../common/CustomLoadingCard';
import CustomCard from '../common/CustomCard';
import CustomTag from '../common/CustomTag';

import OrderPaperImage from './parts/OrderPaperImage';

import { formatPhoneNumber, enumOrderStatus } from '../../helpers';

class ReleasedOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            order: null,
            orderId: this.props.match.params.orderId,
            acceptedStatus: [
                enumOrderStatus.released,
                enumOrderStatus.canceled
            ],
        };
    }

    getOrderInformation = () => {
        return BaseStore.httpClient.get(`/api/orders/${this.state.orderId}`);
    }

    renderOrder = () => {
        let itemsList = [];
        const { loading, order, acceptedStatus } = this.state;

        if (loading && order === null) {
            return (
                <CustomLoadingCard />
            );
        }

        if (!acceptedStatus.includes(order.orderStatus)) {
            this.props.history.push(`/orders`);
        }

        const { orderLineItems, shipments, transportAttrs, customerInfo, orderType, orderCreatedTs } = order;

        itemsList.push(
            <CustomCard key={'orderInformationCard'} title='Order Information'>
                <Grid container className='card-content'>
                    <Grid item xs={6}>
                        <Typography className='text-18px text-bold pb-20px' color="primary">
                            {transportAttrs.orderNumber}
                        </Typography>
                        <Typography className='text-capitalize' variant="body2" color="black" gutterBottom>
                            Customer: {customerInfo.name}
                        </Typography>
                        <Typography variant="body2" color="black" gutterBottom>
                            Phone: {formatPhoneNumber(customerInfo.phone)}
                        </Typography>
                        <Typography variant="body2" color="black" gutterBottom>
                            Street: {customerInfo.streetAddress}
                        </Typography>
                        <Typography variant="body2" color="black" gutterBottom>
                            City/State: {customerInfo.city} {customerInfo.state}
                        </Typography>
                        <Typography variant="body2" color="black" gutterBottom>
                            Zip Code: {customerInfo.zip}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <CardContent className='p-0 text-right h-full'>
                            <div className='flex justify-end pb-20px'>
                                <CustomTag order={order} />
                            </div>
                            <Typography variant="body2" color="black" gutterBottom>
                                Number of Items: {orderLineItems.length}
                            </Typography>
                            <Typography variant="body2" color="black" gutterBottom>
                                Date Placed: {moment(orderCreatedTs).format('YYYY-MM-DD hh:mm')}
                            </Typography>
                            <Typography variant="body2" color="black" gutterBottom>
                                Type: {orderType === enumOrderStatus.bopis ? 'Pickup' : 'Ship To Home'}
                            </Typography>
                        </CardContent>
                    </Grid>
                </Grid>
            </CustomCard>
        );

        if (shipments && shipments?.length > 0) {
            itemsList.push(
                <CustomCard key={'shippingInformationCard'} title='Shipping Information'>
                    {shipments.map((shipment, index) => {
                        return (
                            <Grid key={index} container className='card-content border-bottom'>
                                <Grid item xs={6}>
                                    <div className='flex flex-column gap-10px'>
                                        {!shipment.isManual && (
                                            <Typography className='text-14px text-semibold' variant="body2" color="black">
                                                Package #{shipment.packageNumber}
                                            </Typography>
                                        )}
                                        <Typography className='text-14px' variant="body2" color="black">
                                            Tracking Number:
                                            <Link underline="none"> {shipment.trackingNumber}</Link>
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className='flex flex-column gap-10px text-right'>
                                        {!shipment.isManual ? (
                                            <Fragment>
                                                <Typography className='text-14px' variant="body2" color="black">
                                                    Carrier and Service Type: {shipment.carrierName} / {shipment.serviceName}
                                                </Typography>
                                                <Typography className='text-14px' variant="body2" color="black">
                                                    L x W x H (in inches): {shipment.dimensions?.length} x {shipment.dimensions?.width} x {shipment.dimensions?.height}
                                                </Typography>
                                                <Typography className='text-14px' variant="body2" color="black">
                                                    Weight (in lbs): {shipment.weight?.value}
                                                </Typography>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <Typography className='text-14px' variant="body2" color="black">
                                                    Carrier and Service Type: {shipment.carrierName}
                                                </Typography>
                                                <Typography className='text-14px' variant="body2" color="black">
                                                    L x W x H (in inches): N/A
                                                </Typography>
                                                <Typography className='text-14px' variant="body2" color="black">
                                                    Weight (in lbs): N/A
                                                </Typography>
                                            </Fragment>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        );
                    })}
                </CustomCard>
            );

        }

        orderLineItems.forEach(orderLineItem => itemsList.push(<OrderPaperImage key={orderLineItem.id} orderLineItem={orderLineItem} />));

        return itemsList;
    }

    UNSAFE_componentWillMount() {
        this.getOrderInformation()
            .then(result => {
                const shipments = result.data?.shipments ? result.data.shipments : [];
                return this.setState({
                    shipments,
                    order: result.data,
                    loading: false,
                });
            });
    }

    render() {
        const { order } = this.state;

        return (
            <React.Fragment>
                <AppBar
                    position="static"
                    color="default"
                    elevation={0}
                    sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                >
                    <Container style={{ maxWidth: '1000px' }}>
                        <Toolbar sx={{ flexWrap: 'wrap' }}>
                            <div className='text-16px text-semibold back pointer-cursor' onClick={() => this.props.history.push(`/orders`)}>
                                <IconButton className='back-button' size="small">
                                    <ChevronLeftIcon />
                                </IconButton>
                                {order === null ? <CircularProgress size={15} /> : (
                                    order.transportAttrs?.orderNumber
                                )}
                            </div>
                        </Toolbar>

                    </Container>
                </AppBar>
                <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 6, pb: 6 }} style={{ maxWidth: '900px' }}>

                    <Grid container spacing={2} sx={{ pb: 6 }}>
                        {this.renderOrder()}
                    </Grid>

                </Container>
            </React.Fragment >
        );
    }
}

export default withRouter(ReleasedOrder);
