import React, { Component } from 'react';
import Routes from './routes';
import BaseStore from './services/BaseStore';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import CustomErrorDialog from './components/common/CustomErrorDialog';

import { millisToMinutesAndSeconds } from './helpers';

import ConfigFile from './Config'

let theme = createTheme({
	palette: {
		primary: {
			main: '#0060A8',
		},
	},
	typography: {
		"fontFamily": `"Open Sans", sans-serif`,
	}
});
theme = responsiveFontSizes(theme);

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			errorMessage: null,
		};
	}

	UNSAFE_componentWillMount() {
		BaseStore.httpClient.interceptors.request.use((config) => {
			if (config) {
				config.headers['x-bf-orgId'] = ConfigFile.orgId;
				config.headers['x-bf-pb-apikey'] = ConfigFile.apiKey;
			}

			return config;
		}, (error) => {
            this.setError(error);
            return Promise.reject(error);
        });

		BaseStore.httpClient.interceptors.response.use((response) => {
			return response;
		}, (error) => {
			this.setError(error);
			return Promise.reject(error);
		});

	}

	setError = (error) => {
		let message = error.message;

		if (error.code && error.code === "ECONNABORTED") {
			message = `Connection error. Please try again.`;
		}

		if (error.response) {
			if (error.response?.status === 401) {
				message = 'Invalid e-mail/password. Please try again.';
			}
			if (error.response?.status === 400) {
				message = `Session timed out after ${millisToMinutesAndSeconds(error?.config?.timeout)} seconds. Please try again.`;
			}
		}

		this.setState({
			error: true,
			errorMessage: message,
		});
	}

	handleClose = () => {
		this.setState({ error: null });
	}

	renderErrorDialog = () => {
		const { error, errorMessage } = this.state;
		return (
			<CustomErrorDialog
				open={error}
				description={errorMessage}
				handleClose={() => this.handleClose()}
			/>
		);
	}

	render() {
		return (
			<ThemeProvider theme={theme}>
				{this.state.error && this.renderErrorDialog()}
				<GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
				<CssBaseline />
				<Routes />
			</ThemeProvider>
		);
	}
}

export default App;