const millisToMinutesAndSeconds = millis => {
    if (millis) {
        const minutes = Math.floor(millis / 60000);
        const seconds = ((millis % 60000) / 1000).toFixed(0);
        if (minutes > 0) {
            return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
        }
        return (seconds < 10 ? '0' : '') + seconds;
    }
    return 'a';
}

export default millisToMinutesAndSeconds;