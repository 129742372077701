import React from 'react';
import { Page, Document, Text, StyleSheet, Image } from '@react-pdf/renderer';
import ConfigFile from '../../../Config'

const styles = StyleSheet.create({
    body: {
        padding: '40px 30px'
    },
    header: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: '20px'
    },
    card: {
        border: 1,
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        padding: '13px 20px',
        marginBottom: '15px',
        borderRadius: '4px',
        borderColor: 'gray'
    },
    cardImage: {
        width: '100px',
        paddingRight: '20px',
    },
    itemOrderNumber: {
        fontSize: 12,
    },
    itemSku: {
        fontSize: 12,
    },
    itemDescription: {
        fontSize: 14,
        color: '#0060a8',
        margin: '5px 0px'
    },
    itemAttrs: {
        fontSize: 12,
    },
    picked: {
        fontSize: 12,
        color: '#0060a8',
        marginTop: '5px'
    },
    orderNumber: {
        fontSize: 16,
        marginBottom: '10px'
    },
});

const OrderPdf = ({ items }) => {

    const renderOrder = () => {
        let orderList = [];

        items.map(item => {
            let itemsList = [];
            const { transportAttrs, orderLineItems } = item;

            orderList.push(
                <Text style={styles.orderNumber}>
                    {transportAttrs.orderNumber}
                </Text>
            );

            orderLineItems.map(orderLineItem => {

                const { sku, description, itemAttrs, pickedQty, orderedQty, imageUrl } = orderLineItem;

                itemsList.push(
                    <div style={styles.card}>
                        <div style={styles.cardImage}>
                            <Image
                                source={{
                                    method: "get",
                                    uri: `${ConfigFile.baseUrl}/api/image?url=${imageUrl}`,
                                    headers: {
                                        Pragma: 'no-cache',
                                        'Cache-Control': 'no-cache',
                                        'Access-Control-Allow-Origin': '*',
                                        crossOrigin: "anonymous"
                                    },
                                }}
                                cache={false}
                                allowDangerousPaths={true}
                                crossOrigin="anonymous"
                            />
                        </div>
                        <div>
                            <Text style={styles.itemOrderNumber}>
                                Order: {transportAttrs.orderNumber}
                            </Text>
                            <Text style={styles.itemSku}>
                                SKU: {sku}
                            </Text>
                            <Text style={styles.itemDescription}>
                                {description}
                            </Text>
                            {
                                itemAttrs?.map(itemAttr => {
                                    return (
                                        <Text style={styles.itemAttrs}>
                                            {itemAttr.label}: {itemAttr.value}
                                        </Text>
                                    );
                                })
                            }
                            <Text style={styles.picked}>
                                Picked: {pickedQty > 0 ? pickedQty : 0} of {orderedQty} item{orderedQty > 1 && 's'}
                            </Text>
                        </div>
                    </div>
                );
                return orderLineItem;
            });

            orderList.push(itemsList);

            return item;
        });

        return orderList;
    }

    return (
        <Document>
            <Page style={styles.body} wrap>
                <Text style={styles.header} >Order List</Text>
                {renderOrder()}
            </Page>
        </Document>
    );
}

export default OrderPdf;