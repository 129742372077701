import React from 'react';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CustomTag from '../../common/CustomTag';
import CustomTextfield from '../../common/CustomTextfield';
import { formatPhoneNumber, enumOrderStatus } from '../../../helpers';

const OrderPaperStaging = ({ order, handleLocation }) => {
    const { customerInfo, orderCreatedTs, orderType, orderLineItems, orderStagingLocation, transportAttrs } = order;

    return (
        <Grid item xs={12}>
            <Paper elevation={2} className='card-content'>
                <Grid container >
                    <Grid item xs={6}>
                        <CardContent className='p-0'>
                            <Typography className='text-18px text-bold pb-20px' color="primary">
                                {transportAttrs.orderNumber}
                            </Typography>
                            <Typography className='text-capitalize' variant="body2" color="black" gutterBottom>
                                Customer: {customerInfo.name}
                            </Typography>
                            <Typography variant="body2" color="black" gutterBottom>
                                Phone: {formatPhoneNumber(customerInfo.phone)}
                            </Typography>
                            <Typography variant="body2" color="black" gutterBottom>
                                Date Placed: {moment(orderCreatedTs).format('YYYY-MM-DD H:m')}
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Grid item xs={6}>
                        <CardContent className='p-0 text-right'>
                            <div className='flex justify-end pb-20px'>
                                <CustomTag order={order} />
                            </div>
                            <Typography variant="body2" color="black">
                                Number of Items: {orderLineItems.length}
                            </Typography>
                            <Typography variant="body2" color="black">
                                Date Placed: {moment(orderCreatedTs).format('YYYY-MM-DD hh:mm')}
                            </Typography>
                            <Typography variant="body2" color="black">
                                Type: {orderType === enumOrderStatus.bopis ? 'Pickup' : 'Ship To Home'}
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Grid item xs={12}>
                        <CardContent className='card-content h-full'>
                            {
                                (
                                    orderType === enumOrderStatus.ship ?
                                        <Typography color="primary" variant="body2">
                                            This is a shipping order. Please attach label to package and stage for carrier pickup.
                                        </Typography> :
                                        <Grid item xs={8}>
                                            <CustomTextfield
                                                multiline
                                                rows={4}
                                                fullWidth
                                                value={orderStagingLocation}
                                                onChange={event => handleLocation(event, order)}
                                                label='Enter one or more staging locations for this order'
                                            />
                                        </Grid>
                                )
                            }
                        </CardContent>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default OrderPaperStaging;
