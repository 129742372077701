import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Button, Menu, MenuItem } from "@mui/material";
import { withStyles } from "@mui/styles";
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircularProgress from '@mui/material/CircularProgress';

const styles = theme => ({
    root: {
        position: "absolute",
        right: 0
    },
    buttonBar: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
        right: 20,
        position: "relative",
        width: "100%",
        display: "flex",
        background: "transparent"
    }
});

const CustomMenuItem = withStyles({
    root: {
        justifyContent: "flex-end"
    }
})(MenuItem);

class CustomAppBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            locationAnchorEl: null,
            user: JSON.parse(localStorage.getItem('user')),
            locations: this.props.locations,
            defaultLocation: JSON.parse(localStorage.getItem('defaultLocation')),
        };
    }

    handleCloseMenuLocation = defaultLocation => {
        this.setState({
            defaultLocation,
            locationAnchorEl: null,
        });
        localStorage.setItem('defaultLocation', JSON.stringify(defaultLocation));
        this.props.setDefaultLocation(defaultLocation);
    };

    handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('metaData');
        localStorage.removeItem('userItems');
        localStorage.removeItem('locations');
        this.props.history.push('/login');
    };

    renderLocations = () => {
        const { locations, defaultLocation } = this.state;
        let locationMenu = [];

        locations?.forEach(location => {
            locationMenu.push(
                <MenuItem
                    onClick={() => this.handleCloseMenuLocation(location)}
                    selected={defaultLocation?.id === location.id}
                >
                    {location.name}
                </MenuItem>
            );
        });

        return locationMenu;
    }

    renderMenuLocations = () => {
        const { locationAnchorEl, defaultLocation } = this.state;
        const { loadingLocation } = this.props;
        const openLocations = Boolean(locationAnchorEl);

        return (
            loadingLocation ? (
                <div style={{ padding: '8px' }}>
                    <CircularProgress sx={{ m: 0.5 }} size={20} />
                </div>
            ) : (
                <React.Fragment>
                    <Button
                        onClick={event => this.setState({ locationAnchorEl: event.currentTarget })}
                        color="inherit"
                        endIcon={<KeyboardArrowDownIcon />}
                        className='text-bold'
                    >
                        {defaultLocation?.name}
                    </Button>
                    <Menu
                        anchorEl={locationAnchorEl}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                        open={openLocations}
                        onClose={() => this.setState({ locationAnchorEl: null })}
                    >
                        <MenuList
                            style={{ textAlign: 'right' }}
                            autoFocusItem={openLocations}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                        >
                            {this.renderLocations()}
                        </MenuList>
                    </Menu>
                </React.Fragment>
            )
        );
    }

    renderMenuSettings = () => {
        const { anchorEl, user } = this.state;
        const open = Boolean(anchorEl);

        return (
            <React.Fragment>
                <Button
                    onClick={event => this.setState({ anchorEl: event.currentTarget })}
                    color="inherit"
                    endIcon={<KeyboardArrowDownIcon />}
                    className='text-bold'
                >
                    {user.name || user.email}
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={open}
                    onClose={() => this.setState({ anchorEl: null })}
                >
                    <MenuList
                        style={{ textAlign: 'right' }}
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                    >

                        <CustomMenuItem>
                            <Typography variant="inherit">
                                {user.name ? user.username : user.email}
                            </Typography>
                        </CustomMenuItem>
                        <Divider />
                        <CustomMenuItem onClick={this.handleLogout}>Logout</CustomMenuItem>
                    </MenuList>
                </Menu>
            </React.Fragment>
        );
    }

    render() {
        const { classes, loadingLocation } = this.props;
        const { locations, defaultLocation } = this.state;

        if (!loadingLocation && !locations) {
            this.setState({
                locations: JSON.parse(localStorage.getItem('locations'))
            }, () => {
                if (!defaultLocation) {
                    this.setState({ defaultLocation: this.state.locations[0] });
                }
            });
        }

        return (
            <div className={classes.root}>
                <div className={classes.buttonBar} id="appbar-collapse">
                    {this.renderMenuLocations()}
                    {this.renderMenuSettings()}
                </div>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(CustomAppBar));
