import React from 'react';
import Typography from '@mui/material/Typography';
import CustomDialog from './CustomDialog';

const CustomErrorDialog = ({ open, handleClose, description }) => {
    description = (
        <React.Fragment>
            <Typography className='text-12px'>
                {description}
            </Typography>
        </React.Fragment>
    );

    return (
        <CustomDialog
            title='Error'
            description={description}
            openDialog={Boolean(open)}
            confirmButton={() => handleClose()}
            confirmLabel='Ok'
            height={20}
        />
    );
}

export default CustomErrorDialog;