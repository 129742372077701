import React from 'react';

const CustomFooter = ({ children }) => {
    return (
        <div className='footer'>
            <div className='footer-inner'>
                {children}
            </div>
        </div>
    )
}

export default CustomFooter;