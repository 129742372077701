import React from 'react';
import _, { isArray } from 'lodash';
import { withRouter } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import BaseStore from '../../services/BaseStore';
import CustomFooter from '../common/CustomFooter';
import CustomButton from '../common/CustomButton';
import CustomDialog from '../common/CustomDialog';

import OrderPaperStaging from './parts/OrderPaperStaging';
import { enumOrderStatus } from '../../helpers';

class StagedOrders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openDialog: false,
            loadingDialog: false,
            user: JSON.parse(localStorage.getItem('user')),
            items: JSON.parse(localStorage.getItem('userItems')),
            itemsStatus: JSON.parse(localStorage.getItem('userItemsStatus')),
        };
    }

    handleClose = () => {
        this.props.history.push('/orders');
        localStorage.removeItem('userItemsStatus');
    }

    handleLocation = (event, order) => {
        let oldItems = this.state.items;
        let item = oldItems.find(item => item.id === order.id);
        item.orderStagingLocation = event.target.value;

        oldItems.splice(_.findIndex(oldItems, { id: order.id }), 1, item);

        this.setState({ items: oldItems });
    }

    handleSaveStaging = () => {
        let items = this.state.items;

        const currentOrder = items.map(item => {
            let newItem = item;
            newItem.orderStagingLocation = isArray(newItem.orderStagingLocation) ? [] : newItem.orderStagingLocation.split("\n");
            newItem.orderStatus = enumOrderStatus.staged;
            return newItem;
        });
        this.setState({ openDialog: true, loadingDialog: true });

        return BaseStore.httpClient.put(`/api/orders?trigger=true`, currentOrder)
            .then(() => {
                localStorage.removeItem('userItems');
                localStorage.removeItem('userItemsStatus');
                this.props.history.push(`/orders`);
            })
            .catch(error => {
                this.setState({
                    openDialog: false,
                    loadingDialog: false,
                });
                return error;
            });
    }

    renderOrder = () => {
        let itemsList = [];

        this.state.items.forEach(item => {
            itemsList.push(
                <OrderPaperStaging order={item} handleLocation={this.handleLocation} />
            );
        });

        return itemsList;
    }

    renderConfirmation = () => {
        const { openDialog, loadingDialog } = this.state;
        return (
            <CustomDialog
                openDialog={openDialog}
                loadingDialog={loadingDialog}
            />
        );
    }

    renderFooter = () => {
        return (
            <CustomFooter>
                <CustomButton
                    onClick={() => this.handleClose()}
                >
                    Close
                </CustomButton>
                <CustomButton
                    variant='contained'
                    color='primary'
                    onClick={() => this.handleSaveStaging()}
                >
                    Save Staging Location
                </CustomButton>
            </CustomFooter>
        );
    }

    render() {
        const { openDialog, itemsStatus, items } = this.state;
        if (!itemsStatus || !items) {
            this.props.history.push(`/orders`);
            return '';
        }

        return (
            <React.Fragment>
                <AppBar
                    position="static"
                    color="default"
                    elevation={0}
                    sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                >
                    <Container style={{ maxWidth: '1000px' }}>
                        <Toolbar sx={{ flexWrap: 'wrap' }}>
                            <div className='back pointer-cursor' onClick={() => this.handleClose()}>
                                <IconButton className='back-button' size="small">
                                    <ChevronLeftIcon />
                                </IconButton>
                                <Typography variant="p" color="inherit" noWrap sx={{ flexGrow: 1, marginRight: '5px' }}>
                                    Staged Orders
                                </Typography>
                            </div>
                        </Toolbar>

                    </Container>
                </AppBar>
                <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 6, pb: 6 }} style={{ maxWidth: '900px' }}>

                    <Grid container spacing={2} sx={{ pb: 6 }}>
                        {!openDialog && this.renderOrder()}
                    </Grid>

                </Container>
                {this.renderConfirmation()}
                {this.renderFooter()}
            </React.Fragment >
        );
    }
}

export default withRouter(StagedOrders);

