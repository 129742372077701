import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const OrderPaperImage = ({ orderLineItem }) => {
    return (
        <Grid item xs={12} >
            <Paper elevation={2}>
                <Grid item className='card-content'>
                    <div className='flex items-center justify-start' xs={12}>
                        <img className='image' height={150} src={orderLineItem.imageUrl} alt='' />
                        <div className='image-description'>
                            <Typography className='text-14px'>
                                Order: {orderLineItem.orderedUOM}
                            </Typography>
                            <Typography className='text-14px'>
                                SKU: {orderLineItem.sku}
                            </Typography>
                            <Typography className='text-16px text-bold py-10px' color='primary'>
                                {orderLineItem.description}
                            </Typography>
                            {
                                orderLineItem.itemAttrs.map((itemAttr, index) => {
                                    return (
                                        <Typography key={index} className='text-14px'>
                                            {itemAttr.label}: {itemAttr.value}
                                        </Typography>
                                    );
                                })
                            }
                            <div className='flex items-center' style={{ height: '28px' }}>
                                <Typography className='text-14px text-bold' color='primary'>
                                    Picked: {orderLineItem.pickedQty} of {orderLineItem.orderedQty} item{orderLineItem.orderedQty > 1 && 's'}
                                </Typography>
                                {
                                    orderLineItem.pickedQty === orderLineItem.orderedQty && (
                                        <CheckCircleIcon className='text-green text-28px' style={{ marginLeft: '10px' }} />
                                    )
                                }
                            </div>
                            {
                                orderLineItem.exception && (orderLineItem.exception.value || orderLineItem.exception.text) && (
                                    <Typography className='text-danger'>
                                        Exception: {orderLineItem.exception.value ? orderLineItem.exception.value : orderLineItem.exception.text}
                                    </Typography>
                                )
                            }
                        </div>
                    </div>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default OrderPaperImage;
