import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import BaseStore from '../../services/BaseStore';

import { withStyles } from "@mui/styles";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

import CustomDialog from '../common/CustomDialog';
import CustomButton from '../common/CustomButton';
import CustomFooter from '../common/CustomFooter';
import CustomLoadingCard from '../common/CustomLoadingCard';

import OrderPaperImageEdit from './parts/OrderPaperImageEdit';
import { enumOrderStatus, orderFilter } from '../../helpers';
import OrderPdf from './pdf/OrderPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';

const styles = theme => ({
    searchBar: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
    }
});

class CompletePickOrders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            name: null,
            loading: true,
            openDialog: false,
            loadingDialog: false,
            orders: [],
            user: JSON.parse(localStorage.getItem('user')),
            items: JSON.parse(localStorage.getItem('userItems')),
            metaData: JSON.parse(localStorage.getItem('metaData')),
            defaultLocation: JSON.parse(localStorage.getItem('defaultLocation')),
        };
    }

    handleClose = () => {
        this.props.history.push(`/orders`);
    }

    updateLastUser = () => {
        const { items, user } = this.state;

        const updateItems = items.map(item => {
            let newItem = item;
            newItem.lastModifiedBy = user.email || user.username;
            newItem.orderStatus = enumOrderStatus.inProgress;
            return newItem;
        });

        this.setState({ loadingDialog: true });

        return BaseStore.httpClient.put('/api/orders?trigger=false', updateItems)
            .then(result => {
                this.setState({ items: result.data, loading: false, openDialog: false, loadingDialog: false });
                return result;
            })
            .catch(err => console.log('Error', err));
    }

    handleQuantityOnChange = (event, index, orderLineItem) => {
        let oldItems = this.state.items;
        let items = oldItems[index];
        const { orderLineItems } = items;
        let newOrderLineItem = orderLineItems.find(lineItem => lineItem.lineItemId === orderLineItem.lineItemId);

        if (parseInt(event.target.value) > newOrderLineItem.orderedQty) {
            newOrderLineItem.pickedQty = parseInt(newOrderLineItem.orderedQty);
        } else {
            newOrderLineItem.pickedQty = event.target.value ? parseInt(event.target.value) : event.target.value;
        }

        if (newOrderLineItem.pickedQty === newOrderLineItem.orderedQty) {
            newOrderLineItem.exception = {};
        }

        items.orderLineItems = orderLineItems;

        oldItems.splice(_.findIndex(oldItems, { id: items.id }), 1, items);

        this.setState({ items: oldItems });
    }

    handleExceptionOnChange = (newValue, index, orderLineItem) => {
        let oldItems = this.state.items;
        let items = oldItems[index];
        const { orderLineItems } = items;
        let newOrderLineItem = orderLineItems.find(lineItem => lineItem.lineItemId === orderLineItem.lineItemId);

        newOrderLineItem.exception = newValue ? newValue : {};

        items.orderLineItems = orderLineItems;

        oldItems.splice(_.findIndex(oldItems, { id: items.id }), 1, items);

        this.setState({ items: oldItems });
    }

    getOrders = () => {
        const { defaultLocation } = this.state;
        let filter = {
            filter: [
                {
                    "field": "orderStatus",
                    "operator": "in",
                    "value": orderFilter['toPick']
                },
                {
                    "field": "siteId",
                    "operator": "==",
                    "value": defaultLocation?.id,
                }
            ]
        }

        return BaseStore.httpClient.post('/api/query', filter)
            .then(result => {
                const orders = result.data.sort((a, b) => {
                    return new Date(a.orderCreatedTs) - new Date(b.orderCreatedTs);
                });

                this.setState({ orders, loading: false });
                return result;
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    error: true,
                });
                console.log(error.message);
            });
    }

    updateItemsFromLocal = () => {
        const { items, orders } = this.state;
        if (orders.length > 0) {
            const updatedItems = items.map(item => {
                return orders.find(order => order.id === item.id);
            });

            this.setState({ items: updatedItems }, () => {
                localStorage.setItem('userItems', JSON.stringify(updatedItems));
            });

        }
    }

    orderHeader = transportAttrs => {
        return (
            <Typography className='text-20px' sx={{ pt: 4 }}>
                {transportAttrs.orderNumber}
            </Typography>
        );
    }

    orderItems = (index, item, orderLineItem) => {
        const { metaData } = this.state;
        const { orderNumber, transportAttrs, orderType } = item;
        const code = `Exceptions-${orderType}`;
        const exception = metaData.find(data => data.field === code)?.data;
        return (
            <OrderPaperImageEdit
                orderNumber={transportAttrs.orderNumber || orderNumber}
                index={index}
                orderLineItem={orderLineItem}
                exception={exception}
                handleQuantityOnChange={this.handleQuantityOnChange}
                handleExceptionOnChange={this.handleExceptionOnChange}
            />
        );
    }

    renderOrder = () => {
        const { items, loading, openDialog } = this.state;
        if (loading && !items) {
            return (
                <CustomLoadingCard />
            );
        } else if (openDialog) {
            return;
        }

        let orderList = [];

        items.map((item, index) => {
            let itemsList = [];
            const { transportAttrs, orderLineItems } = item;

            orderList.push(this.orderHeader(transportAttrs));

            orderLineItems.map(orderLineItem => {
                itemsList.push(this.orderItems(index, item, orderLineItem));
                return orderLineItem;
            });

            orderList.push(itemsList);
            return item;
        });

        return orderList;
    }

    handleCompletePick = () => {
        localStorage.setItem('userItems', JSON.stringify(this.state.items))
        localStorage.setItem('userItemsStatus', true);
        this.props.history.push(`/orders/staged-orders`);
    }

    handlePrintPage = () => {
        window.print();
    }

    renderFooter = () => {
        let orderLineItems = [];
        this.state.items.forEach(item => {
            item.orderLineItems.forEach(orderLineItem => {
                orderLineItems.push(orderLineItem);
            })
        });

        const count = orderLineItems.filter(orderLineItem => {
            if ((orderLineItem.pickedQty === 0 || isNaN(orderLineItem.pickedQty) || orderLineItem.pickedQty < orderLineItem.orderedQty) && orderLineItem.exception && (Object.keys(orderLineItem.exception).length === 0 || orderLineItem.exception.id === 0)) {
                return orderLineItem;
            }
            return '';
        });

        return (
            <CustomFooter>
                <CustomButton
                    onClick={() => this.props.history.push(`/orders`)}
                >
                    Close
                </CustomButton>
                <CustomButton
                    disabled={count.length > 0}
                    variant='contained'
                    color='primary'
                    onClick={() => this.handleCompletePick()}
                >
                    Complete Pick
                </CustomButton>
            </CustomFooter>
        );
    }

    renderConfirmation = () => {
        const { name, openDialog, loadingDialog } = this.state;
        return (
            <CustomDialog
                title='Info'
                description={`Order is currently being picked by ${name}, do you want to resume pick for this order?`}
                openDialog={openDialog}
                loadingDialog={loadingDialog}
                onClose={() => this.handleClose()}
                cancelButton={() => this.handleClose()}
                confirmButton={() => this.updateLastUser()}
                cancelLabel='Cancel'
                confirmLabel='Ok'
                {...this.state}
            />
        );
    }

    componentWillMount() {
        const { items, user } = this.state;
        if (items?.filter(item => item.lastModifiedBy === '').length > 0) {
            // update the `lastModifiedBy` if there's no value yet
            this.updateLastUser();
        } else {
            this.getOrders()
                .then(results => {

                    // filter the updated versions of orders to avoid error 409
                    const { data } = results;
                    const newItems = data.filter(d => {
                        return items.find(item => item.id === d.id);
                    });

                    this.setState({ items: newItems }, () => {
                        // start checking of item's lastModifiedBy if it is equal to current user
                        let lastModifiedBy = "";
                        const checkItems = newItems.filter(item => {
                            const checkName = item.lastModifiedBy ? item.lastModifiedBy !== (user.email || user.username) : false;
                            if (checkName) {
                                lastModifiedBy = item.lastModifiedBy;
                            }
                            return checkName;
                        });

                        if (checkItems.length > 0) {
                            this.setState({ 
                                openDialog: true, 
                                loading: false, 
                                name: lastModifiedBy
                            });
                        }
                    });
                })
                .catch(error => {
                    this.setState({
                        error: true,
                    });
                    return error;
                });
        }
    }

    render() {
        const { items, loading, openDialog, error } = this.state;

        if (loading) {
            return (
                <CustomDialog
                    openDialog={loading}
                    loadingDialog={true}
                />
            );
        }

        return (
            <React.Fragment>
                <AppBar
                    className='app-bar'
                    position="static"
                    color="default"
                    elevation={0}
                    sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                >
                    <Container style={{ maxWidth: '1000px' }}>
                        <Toolbar className='flex justify-between'>
                            <div className='back pointer-cursor' onClick={() => this.props.history.push(`/orders`)}>
                                <IconButton className='back-button' size="small">
                                    <ChevronLeftIcon />
                                </IconButton>
                                <Typography className='text-16px text-semibold' variant="p" color="inherit" noWrap sx={{ flexGrow: 1, marginRight: '5px' }}>
                                    Pick Order {items.length > 1 && '(Multiple Orders)'}
                                </Typography>
                                {items === null ? <CircularProgress size={15} /> : items.orderNumber}
                            </div>
                            {!openDialog && (
                                <PDFDownloadLink document={<OrderPdf items={items} />} fileName='orderList' className='text-normal'>
                                    <CustomButton
                                        startIcon={<PrintOutlinedIcon />}
                                    >
                                        Print
                                    </CustomButton>
                                </PDFDownloadLink>
                            )}
                        </Toolbar>

                    </Container>
                </AppBar>
                <Container disableGutters maxWidth="lg" component="main" sx={{ pb: 6 }} style={{ maxWidth: '900px' }}>

                    <Grid container sx={{ pb: 6 }} style={{ gap: '20px' }}>
                        {this.renderOrder()}
                    </Grid>

                </Container>
                {this.renderConfirmation()}
                {items && !loading && !openDialog && !error && this.renderFooter()}
            </React.Fragment >
        );
    }
}

export default withRouter(withStyles(styles)(CompletePickOrders));
