import React from 'react';
import { withRouter } from 'react-router-dom';
import BaseStore from '../../services/BaseStore';

import { withStyles } from "@mui/styles";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import SearchIcon from '@mui/icons-material/Search';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import CustomAppBar from '../CustomAppBar';
import CustomLoadingCard from '../common/CustomLoadingCard';
import CustomTextfield from '../common/CustomTextfield';

import CustomDialog from '../common/CustomDialog';
import OrderPaper from './parts/OrderPaper';
import { enumOrderStatus, orderFilter } from '../../helpers';

const styles = theme => ({
    searchBar: {
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
    }
});

class Orders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tabValue: 'toPick',
            selectedOrder: null,
            filter: orderFilter['toPick'],
            displayAll: false,
            search: '',
            loading: false,
            loadingLocation: false,
            toPick: 0,
            staged: 0,
            all: 0,
            openDialog: false,
            orders: [],
            orderData: [],
            status: [
                enumOrderStatus.pickRequired,
                enumOrderStatus.inProgress,
                enumOrderStatus.staged,
            ],
            stagedAcceptedStatus: [
                enumOrderStatus.staged,
                enumOrderStatus.toRelease
            ],
            releasedAcceptedStatus: [
                enumOrderStatus.released,
                enumOrderStatus.canceled
            ],
            locations: JSON.parse(localStorage.getItem('locations')),
            defaultLocation: JSON.parse(localStorage.getItem('defaultLocation')),
        };
    }

    handleChange = (event) => {
        this.setState({ search: event.target.value });
    }

    handleClose = () => {
        this.setState({ openDialog: false });
    }

    handleJustOne = () => {
        localStorage.setItem('userItems', JSON.stringify([this.state.selectedOrder]));
        this.props.history.push(`/orders/complete-pick`);
    }

    handleSelectOrders = () => {
        localStorage.setItem('userItems', JSON.stringify([this.state.selectedOrder]));
        this.props.history.push(`/orders/to-pick`);
    }

    handleOrderClick = (order) => {
        const { id, orderStatus } = order;
        const { orderData, stagedAcceptedStatus, releasedAcceptedStatus } = this.state;
        if (stagedAcceptedStatus.includes(orderStatus)) {
            this.props.history.push(`/orders/staged/${id}`);
        } else if (releasedAcceptedStatus.includes(orderStatus)) {
            this.props.history.push(`/orders/released-order/${id}`);
        } else {
            if (orderData.length === 1) {
                this.setState({ selectedOrder: order }, () => {
                    this.handleJustOne();
                });
            } else {
                this.setState({ selectedOrder: order, openDialog: true });
            }
        }
    }

    includeFilter = () => {
        const { orders, status } = this.state;
        const newOrders = orders.filter(order => status.indexOf(order.orderStatus) > -1);

        this.setState({ orderData: newOrders, all: newOrders.length });
    }

    renderOrders = () => {
        let newOrders;
        let content = [];
        let { search, orderData, loading, loadingLocation } = this.state;

        if (loading) {
            return (
                <CustomLoadingCard />
            );
        }

        if (search) {
            search = search.toLowerCase().trim();
            newOrders = orderData.filter(order => {
                const { transportAttrs, customerInfo } = order;
                return transportAttrs?.orderNumber.toLowerCase().includes(search) || customerInfo?.name.toLowerCase().includes(search) || customerInfo?.phone?.includes(search);
            });
        } else {
            newOrders = orderData;
        }

        newOrders.forEach(order => {
            content.push(
                <OrderPaper order={order} handleOrderClick={this.handleOrderClick} />
            );
        });

        if (content.length === 0) {
            return (
                <Grid item xs={12}>
                    <Paper elevation={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <CardContent className='card-content'>
                                    <Typography className='text-20px text-semibold' color="primary" gutterBottom>
                                        {loadingLocation ? 'Locations is loading, please wait.' : 'No results found.'}
                                    </Typography>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            );
        }

        return content;
    }

    getOrders = () => {
        const { defaultLocation, tabValue } = this.state;
        let filter = {
            filter: [
                {
                    "field": "orderStatus",
                    "operator": "in",
                    "value": orderFilter[tabValue],
                },
                {
                    "field": "siteId",
                    "operator": "==",
                    "value": defaultLocation?.id,
                }
            ]
        }

        return BaseStore.httpClient.post('/api/query', filter)
            .then(result => {
                const orders = result.data.sort((a, b) => {
                    return new Date(a.orderCreatedTs) - new Date(b.orderCreatedTs);
                });

                if (tabValue === 'toPick') {
                    this.setState({ toPick: orders.length });
                } else if (tabValue === 'staged') {
                    this.setState({ staged: orders.length });
                }

                this.setState({ orders, orderData: orders, loading: false }, () => {
                    if (tabValue === 'all') {
                        this.includeFilter();
                    }
                });
                return result;
            })
            .catch(error => {
                this.setState({
                    loading: false,
                });
                return error;
            });
    }

    handleTabChange = (event, tabValue) => {
        const filter = orderFilter[tabValue];

        this.setState({ tabValue, filter }, () => {
            this.newRequest();
        });
    }


    handleFilterChange = (event) => {
        const { target: { value } } = event;

        this.setState({ status: value }, () => {
            this.includeFilter();
        });
    };

    newRequest = () => {
        this.setState({ loading: true, search: '' }, () => {
            this.getOrders();
        });
    }

    setDefaultLocation = defaultLocation => {
        this.setState({ defaultLocation, loading: true, toPick: 0, staged: 0, all: 0 }, () => {
            this.getOrders();
        });
    }

    getLocations = () => {
        return BaseStore.httpClient.get('/oms/api/list/locations');
    }

    UNSAFE_componentWillMount() {
        const { locations, defaultLocation } = this.state;
        if (defaultLocation && locations) {
            this.newRequest();
        } else {
            this.setState({ loadingLocation: true });
            this.getLocations()
                .then(results => {
                    localStorage.setItem('locations', JSON.stringify(results.data));
                    localStorage.setItem('defaultLocation', JSON.stringify(results.data[0]));

                    this.setState({
                        locations: results.data,
                        defaultLocation: results.data[0],
                        loadingLocation: false
                    }, () => {
                        this.newRequest();
                    });
                });
        }
    }

    render() {
        const { classes } = this.props;
        const { tabValue, loading, toPick, staged, all, search, openDialog, defaultLocation, loadingLocation, locations, status } = this.state;

        return (
            <React.Fragment>
                <AppBar
                    position="static"
                    color="default"
                    elevation={0}
                    sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
                >
                    <Container style={{ maxWidth: '1000px' }}>
                        <Toolbar sx={{ flexWrap: 'wrap' }}>
                            <Typography
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1 }}
                                className='text-semibold'
                            >
                                Fulfillment
                            </Typography>
                            <CustomAppBar
                                locations={locations}
                                loadingLocation={loadingLocation}
                                defaultLocation={defaultLocation}
                                setDefaultLocation={this.setDefaultLocation}
                            />
                        </Toolbar>

                    </Container>
                </AppBar>
                <Container container maxWidth="lg" component="main" className='main-container'>

                    <Grid container className='filter-container'>
                        <Grid className='flex' item sm={6} xs={6}>
                            <Tabs
                                value={tabValue}
                                onChange={this.handleTabChange}
                                aria-label="wrapped label tabs"
                            >
                                <Tab
                                    value='toPick'
                                    disabled={loading}
                                    label={`To Pick ${(toPick > 0 ? '(' + toPick + ')' : '')}`}
                                    className='text-14px text-semibold text-transform-normal'
                                />
                                <Tab
                                    value='staged'
                                    disabled={loading}
                                    label={`Staged ${(staged > 0 ? '(' + staged + ')' : '')}`}
                                    className='text-14px text-semibold text-transform-normal'
                                />
                                <Tab
                                    value='all'
                                    disabled={loading}
                                    label={`All ${(all > 0 ? '(' + all + ')' : '')}`}
                                    className='text-14px text-semibold text-transform-normal'
                                />
                            </Tabs>
                        </Grid>
                        <Grid item xs={6} className={classes.searchBar}>
                            <CustomTextfield
                                fullWidth
                                size='small'
                                value={search}
                                label="Enter name, phone or order number to search"
                                InputProps={{ endAdornment: <SearchIcon /> }}
                                onChange={(e) => this.handleChange(e)}
                            />
                        </Grid>

                        {tabValue === 'all' && (
                            <Grid item xs={12} className='flex justify-end pt-20px'>
                                <FormControl sx={{ width: 162 }}>
                                    <InputLabel className='text-14px' style={{ marginTop: '-6px' }}>
                                        Filter
                                    </InputLabel>
                                    <Select
                                        size='small'
                                        multiple
                                        value={status}
                                        className='filter-select'
                                        onChange={this.handleFilterChange}
                                        input={<OutlinedInput label="Status" />}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {orderFilter['all'].map((s) => (
                                            <MenuItem className='menu-item' key={s} value={s}>
                                                <Checkbox className='py-0 pl-0' checked={status.indexOf(s) > -1} />
                                                <ListItemText className='menu-item-text' primary={s} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                    </Grid>

                    <Grid container spacing={2}>
                        {this.renderOrders()}
                    </Grid>

                </Container>
                <CustomDialog
                    title='Pick Multiple Orders?'
                    description='Include other orders to pick with this order?'
                    openDialog={openDialog}
                    onClose={() => this.handleClose()}
                    cancelButton={() => this.handleJustOne()}
                    confirmButton={() => this.handleSelectOrders()}
                    cancelLabel='No, just this one'
                    confirmLabel='Select Multiple Orders'
                    {...this.state}
                />
            </React.Fragment >
        );
    }
}

export default withRouter(withStyles(styles)(Orders));
