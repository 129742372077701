const orderStatus = {
    all: 'ALL',
    ship: 'SHIP',
    bopis: 'BOPIS',
    staged: 'STAGED',
    released: 'RELEASED',
    canceled: 'CANCELED',
    toRelease: 'TO RELEASE',
    reassigned: 'REASSIGNED',
    inProgress: 'IN PROGRESS',
    customerCare: 'CUSTOMER_CARE',
    pickRequired: 'PICK REQUIRED',
}

export default orderStatus;