import React from 'react';

const WindowsIcon = () => {
    return (
        <svg width="26px" height="26px" viewBox="0 0 256 242" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
            <title>Azure</title>
            <defs>
                <linearGradient x1="58.9717389%" y1="7.4114724%" x2="37.1905196%" y2="103.76182%" id="Azure-linearGradient-1">
                    <stop stop-color="#114A8B" offset="0%"></stop>
                    <stop stop-color="#0669BC" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="59.7188447%" y1="52.3127289%" x2="52.6914332%" y2="54.8636556%" id="Azure-linearGradient-2">
                    <stop stop-color="#000000" stop-opacity="0.3" offset="0%"></stop>
                    <stop stop-color="#000000" stop-opacity="0.2" offset="7.1%"></stop>
                    <stop stop-color="#000000" stop-opacity="0.1" offset="32.1%"></stop>
                    <stop stop-color="#000000" stop-opacity="0.05" offset="62.3%"></stop>
                    <stop stop-color="#000000" stop-opacity="0" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="37.2789399%" y1="4.60005789%" x2="62.4733904%" y2="99.9794963%" id="Azure-linearGradient-3">
                    <stop stop-color="#3CCBF4" offset="0%"></stop>
                    <stop stop-color="#2892DF" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g>
                <path d="M85.3432536,0.00290931225 L161.095926,0.00290931225 L82.4572158,233.000999 C80.7999506,237.910266 76.1963409,241.216897 71.0148908,241.216897 L12.0605874,241.216897 C8.17631708,241.216897 4.52963465,239.346777 2.26398956,236.191716 C-0.00165554136,233.036655 -0.608845705,228.983428 0.632808896,225.302959 L73.8980192,8.2188071 C75.5546242,3.30737742 80.1599649,0 85.3432536,0 L85.3432536,0.00290931225 Z" fill="url(#Azure-linearGradient-1)"></path>
                <path d="M195.422901,156.282432 L75.2973984,156.282432 C73.0119231,156.279908 70.9577304,157.676287 70.1192383,159.802395 C69.2807461,161.928503 69.8287946,164.351151 71.5007459,165.909349 L148.690618,237.955558 C150.937998,240.051977 153.897138,241.216897 156.970521,241.216897 L224.990241,241.216897 L195.422901,156.282432 Z" fill="#0078D4"></path>
                <path d="M85.3432536,0.00282026831 C80.0982223,-0.017218565 75.4499031,3.37677358 73.8718354,8.37881927 L0.722997576,225.105126 C-0.602635687,228.800183 -0.0443562611,232.909431 2.21904685,236.116884 C4.48244996,239.324337 8.16695154,241.227505 12.0925898,241.216941 L72.5684635,241.216941 C77.154039,240.397586 80.9441027,237.174364 82.4892183,232.779891 L97.0765099,189.788984 L149.182292,238.389046 C151.365814,240.195147 154.104928,241.193803 156.938519,241.216941 L224.705129,241.216941 L194.983595,156.282435 L108.341367,156.3028 L161.369401,0.00282026831 L85.3432536,0.00282026831 Z" fill="url(#Azure-linearGradient-2)"></path>
                <path d="M182.098251,8.20716985 C180.443985,3.30365177 175.845517,0.00290931225 170.670472,0.00290931225 L86.2451404,0.00290931225 C91.4198966,0.00290931225 96.0178956,3.30421162 97.6729189,8.20716985 L170.941039,225.30005 C172.18363,228.981191 171.576996,233.035501 169.311351,236.191716 C167.045705,239.34793 163.398467,241.219806 159.51326,241.219806 L243.941501,241.219806 C247.826196,241.219806 251.472566,239.346605 253.737531,236.190534 C256.002496,233.034464 256.608798,228.980705 255.366371,225.30005 L182.098251,8.20716985 Z" fill="url(#Azure-linearGradient-3)"></path>
            </g>
        </svg>

    );
}

export default WindowsIcon;
