import React from 'react';
import { styled } from "@mui/styles";
import Button from '@mui/material/Button';

const ButtonStyled = styled(Button)({
    minWidth: '150px !important',
    height: '40px !important',
    padding: '0 20px !important',
    borderRadius: '5px !important',
    fontSize: '12px !important',
    textTransform: 'capitalize !important',
})

const CustomButtom = ({ children, ...props }) => {

    return (
        <ButtonStyled {...props}>
            {children}
        </ButtonStyled>
    )
}

export default CustomButtom;
