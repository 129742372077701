import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import CustomTextfield from '../../common/CustomTextfield';
import CustomSelect from '../../common/CustomSelect';

const OrderPaperImageEdit = ({ orderNumber, index, orderLineItem, exception, handleQuantityOnChange, handleExceptionOnChange }) => {

    return (
        <Grid item xs={12} >
            <Paper elevation={2}>
                <Grid item className='card-content'>
                    <div className='flex items-center justify-start' xs={12}>
                        <img className='px-20px' height={150} src={orderLineItem.imageUrl} alt='' />
                        <div className='px-20px h-full'>
                            <Typography className='text-14px'>
                                Order: {orderNumber}
                            </Typography>
                            <Typography className='text-14px'>
                                SKU: {orderLineItem.sku}
                            </Typography>
                            <Typography className='text-18px py-10px' color='primary'>
                                {orderLineItem.description}
                            </Typography>
                            {
                                orderLineItem.itemAttrs.map((itemAttr, key) => {
                                    return (
                                        <Typography key={key} className='text-14px'>
                                            {itemAttr.label}: {itemAttr.value}
                                        </Typography>
                                    );
                                })
                            }
                            <div className='flex items-center my-10px' style={{ height: '28px' }}>
                                <Typography className='text-14px text-semibold' color='primary'>
                                    Picked: {orderLineItem.pickedQty > 0 ? orderLineItem.pickedQty : 0} of {orderLineItem.orderedQty} item{orderLineItem.orderedQty > 1 && 's'}
                                </Typography>
                                {
                                    orderLineItem.pickedQty === orderLineItem.orderedQty && (
                                        <CheckCircleIcon className='text-green text-28px' style={{ marginLeft: '10px' }} />
                                    )
                                }
                            </div>
                            {
                                orderLineItem.exception && orderLineItem.exception.text && (
                                    <Typography className='text-danger'>
                                        Exception: {orderLineItem.exception.text}
                                    </Typography>
                                )
                            }
                            <div className='staged-fields'>
                                <CustomTextfield
                                    required
                                    autoComplete='off'
                                    onChange={event => handleQuantityOnChange(event, index, orderLineItem)}
                                    value={orderLineItem.pickedQty >= 0 ? orderLineItem.pickedQty : ''}
                                    label="Pick Quantity"
                                    name="quantity"
                                    size="small" 
                                />
                                {
                                    orderLineItem.exception && orderLineItem.exception.text ?
                                        <CustomSelect
                                            required
                                            label='Exception'
                                            className='w-300px'
                                            value={orderLineItem.exception ? [orderLineItem.exception][0] : []}
                                            options={orderLineItem.exception ? [orderLineItem.exception] : exception}
                                        /> :

                                        <CustomSelect
                                            required
                                            label='Exception'
                                            className='w-300px'
                                            options={exception}
                                            value={Object.keys(orderLineItem.exception).length > 0 ? orderLineItem.exception : null}
                                            disabled={orderLineItem.pickedQty === orderLineItem.orderedQty}
                                            onChange={(event, newValue) => handleExceptionOnChange(newValue, index, orderLineItem, event)}
                                        />
                                }
                            </div>
                        </div>
                    </div>
                </Grid>
            </Paper>
        </Grid>
    )
}

OrderPaperImageEdit.propTypes = {
    exception: PropTypes.array,
}

OrderPaperImageEdit.defaultProps = {
    exception: [],
}

export default OrderPaperImageEdit;
