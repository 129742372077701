import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const CustomCard = ({ title, children, ...props }) => {

    return (
        <Grid item xs={12}>
            <Paper elevation={2}>
                {
                    title && (
                        <div className='card-header'>
                            {title}
                        </div>
                    )
                }
                {children}
            </Paper>
        </Grid>
    )
}

export default CustomCard;
