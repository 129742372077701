import React from 'react';
import Snackbar from '@mui/material/Snackbar';

const CustomToast = ({ openSnack, message, ...props }) => {
    return (
        <Snackbar
            open={openSnack}
            autoHideDuration={5000}
            message={message}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            {...props}
        />
    );
}

export default CustomToast;
