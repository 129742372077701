import * as axios from 'axios';
import http from 'http';
import https from 'https';

import ConfigFile from '../Config'

export class BaseStore {

    getBaseUrl = () => {
        console.log(`baseUrl is ${ConfigFile.baseUrl}, orgId is: ${ConfigFile.orgId}`);
        return ConfigFile.baseUrl;
    }

    httpClient = axios.create({
        baseURL: this.getBaseUrl(),
        timeout: ConfigFile.timeout,
        //keepAlive pools and reuses TCP connections, so it's faster
        httpAgent: new http.Agent({ keepAlive: true }),
        httpsAgent: new https.Agent({ keepAlive: true }),
        headers: {
            'Content-Type': 'application/json'
        }
    });

}


export default new BaseStore();