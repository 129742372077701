import config from '../Config.js';

export const msalConfig = {
    auth: {
        clientId: config.appId,
        redirectUri: config.redirectUrl,
        authority: config.authority
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
    }
};

export const msalScopes = {
    scopes: config.scopes,
    prompt: 'select_account'
};