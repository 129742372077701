import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import CustomButton from './CustomButton';

const CustomDialog = (props) => {
    const {
        title,
        description,
        openDialog,
        onClose,
        confirmButton,
        height,
        cancelButton,
        cancelLabel,
        confirmLabel,
        loadingDialog,
        disabledConfirm,
        disabledLoadingConfirm,
        small
    } = props;
    if (openDialog && loadingDialog) {
        return (
            <Dialog fullWidth open={true}>
                <DialogContent className='flex items-center justify-center' style={{ height: '150px' }}>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
        );
    }

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    }

    return (
        <Dialog
            fullWidth
            open={openDialog}
            onClose={() => handleClose()}
            className={`${small && 'main-dialog'}`}
        >
            <DialogTitle className='dialog-title'>
                {title}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 18,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon fontSize='small' />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent className='dialog-content' sx={{ height: height ? `${height}px` : '100px' }}>
                <div className='text-black'>
                    {description}
                </div>
            </DialogContent>
            <DialogActions className='dialog-footer'>
                {
                    cancelButton && (
                        <CustomButton
                            color='primary'
                            onClick={() => cancelButton()}
                        >
                            {cancelLabel}
                        </CustomButton>
                    )
                }
                {
                    confirmButton && (
                        <CustomButton
                            color='primary'
                            variant='contained'
                            onClick={() => confirmButton()}
                            disabled={disabledConfirm}
                        >
                            {confirmLabel}
                            {disabledLoadingConfirm && <CircularProgress sx={{ ml: 2 }} size={20} />}
                        </CustomButton>
                    )
                }
            </DialogActions>
        </Dialog>
    );
}

export default CustomDialog;
