import React from 'react';
import BadgeUnstyled from '@mui/core/BadgeUnstyled';
import { enumOrderStatus } from '../../helpers';

const CustomTag = ({ order }) => {

    const checkChipBackground = () => {
        switch (order.orderStatus) {
            case enumOrderStatus.pickRequired:
                return 'bg-blue';

            case enumOrderStatus.inProgress:
                return 'bg-gray';

            case enumOrderStatus.canceled:
                return 'bg-red';

            default:
                if (order.orderType === 'SHIP') {
                    return 'bg-orange';
                }
                return 'bg-green';
        }
    }

    if (order.orderStatus === enumOrderStatus.pickRequired || order.orderStatus === enumOrderStatus.inProgress) {
        return (
            <BadgeUnstyled className={`chip ${checkChipBackground()}`}>
                {(order.orderStatus === enumOrderStatus.inProgress ? 'Pick in Progress' : 'Pick Required')}
            </BadgeUnstyled>
        );
    }

    if (order.orderStatus === enumOrderStatus.staged || order.orderStatus === enumOrderStatus.toRelease) {
        return (
            <BadgeUnstyled className={`chip ${checkChipBackground()}`}>
                {order.orderType === enumOrderStatus.ship ? 'Ready to Ship' : 'Ready for Pickup'}
            </BadgeUnstyled>
        );
    }

    return (
        <BadgeUnstyled className={`chip ${checkChipBackground()}`}>
            {order.orderStatus}
        </BadgeUnstyled>
    );
}

export default CustomTag;
