import React from 'react';
import { withRouter } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';

class NotFound extends React.Component {

    render() {
        return (
            <React.Fragment>
                <AppBar
                    position="static"
                    color="default"
                    elevation={0}
                >
                    <Container style={{ maxWidth: '1000px' }}>
                        <Toolbar sx={{ flexWrap: 'wrap' }}>
                            <Typography variant="h6" color="inherit" className='pointer-cursor' noWrap sx={{ flexGrow: 1 }} onClick={() => this.props.history.push('/orders')}>
                                Fulfillment
                            </Typography>
                        </Toolbar>

                    </Container>
                </AppBar>
                <Container disableGutters maxWidth="lg" component="main" sx={{ pt: 6, pb: 6 }} style={{ maxWidth: '900px' }}>
                    <Grid container spacing={2} sx={{ pb: 6 }}>
                        <Grid item xs={12}>
                            <Paper elevation={2}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <CardContent className='card-content flex justify-center'>
                                            <Typography variant='h4'>
                                                Page not found
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment >
        );
    }
}

export default withRouter(NotFound);
