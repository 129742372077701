import { enumOrderStatus } from './';

const orderFilter = {
    'toPick': [
        enumOrderStatus.pickRequired,
        enumOrderStatus.inProgress
    ],
    'staged': [
        enumOrderStatus.staged,
    ],
    'all': [
        enumOrderStatus.pickRequired,
        enumOrderStatus.inProgress,
        enumOrderStatus.staged,
        enumOrderStatus.released,
        enumOrderStatus.canceled,
    ],
}

export default orderFilter;