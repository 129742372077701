import React, { Fragment, memo } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import isAuthorized from '../utils/isAuthorized';

import Orders from '../components/order/Orders.jsx';
import Staged from '../components/order/Staged.jsx';
import ReleasedOrder from '../components/order/ReleasedOrder.jsx';
import ToPickOrders from '../components/order/ToPickOrders.jsx';
import CompletePickOrders from '../components/order/CompletePickOrders.jsx';
import StagedOrders from '../components/order/StagedOrders.jsx';
import NotFound from '../components/common/NotFound.jsx';

const PrivateRoutes = () => {
    
    if (!isAuthorized()) {
        return <Redirect to={`/login`} />
    }

    return (
        <Fragment>
            <Switch>
                <Route path={`/orders/staged/:orderId`} render={(props) =>
                    <Staged {...props} />
                } />
                <Route path={`/orders/released-order/:orderId`} render={(props) =>
                    <ReleasedOrder {...props} />
                } />
                <Route path={`/orders/to-pick`} render={(props) =>
                    <ToPickOrders {...props} />
                } />
                <Route path={`/orders/complete-pick`} render={(props) =>
                    <CompletePickOrders {...props} />
                } />
                <Route path={`/orders/staged-orders`} render={(props) =>
                    <StagedOrders {...props} />
                } />
                <Route exact path="/orders">
                    <Orders />
                </Route>
				<Route>
                    <NotFound />
				</Route>
            </Switch>
        </Fragment>
    );
}

export default memo(PrivateRoutes);